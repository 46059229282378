<template>
  <b-container>
    <b-row>
      <!-- <b-col
          cols="12"
          class="py-3 border"
        >
          Header
        </b-col> -->

      <b-col
        cols="12"
        class="py-3"
      >
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex flex-column justify-content-center"
          >
            <div class="pb-4">
              <h1 class="text-primary text-left">
                Enjoy a better
              </h1>
              <h1 class="text-dark text-left">
                payment experience
              </h1>
            </div>
            <div class="pb-4">
              <h3>Accept payments and send funds easily. We also provide easy way to manage all your payment processes.</h3>
            </div>
            <div class="d-flex justify-content-md-start">
              <b-button
                variant="primary"
                class="mr-2"
              >
                Get Started
              </b-button>
              <b-button
                variant="outline-info"
              >
                WATCH VIDEOS
              </b-button>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-center align-items-center"
          >
            <b-img
              :src="require('@/assets/images/3d/send-money.png')"
              style="z-index: 1; transform: rotate(-5deg)"
            />
            <div class="cover" />
            <div class="cover-two" />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <h4 class="pb-4">
              We are trusted by 1 Million+ Banks
            </h4>
            <b-img
              :src="require('@/assets/images/3d/banks.jpg')"
              style="z-index: 1; opacity: .3; width: 100%"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

export default {

  name: 'LandingPage',

  middleware: ['auth'],

  metaInfo: () => ({
    title: 'LandingPage'
  })
}
</script>
<style>
h1 {
  font-weight: bolder !important;
  font-size: 60px !important;
}

h3 {
  text-align: justify !important;
  font-size: 24px !important;
}

.cover {
  background: var(--iq-primary);
  width: 300px;
  height: 350px;
  position: absolute;
  z-index: 0;
  bottom: 40px;
  border-radius: 50% 50% 20px 20px;
}
</style>
